<template>
  <v-form ref="form">
    <v-img :src="imageSrc"></v-img>
    <div v-if="inputType === 'text'" style="height: auto">
      <v-text-field @keydown.enter.prevent="next" autofocus validate-on-blur :rules="rules.required" placeholder="Akú emóciu vidíte ?" v-model="object.fromTextArea"></v-text-field>
    </div>
    <div v-else-if="inputType === 'radio'">
      <v-radio-group v-model="object.fromRadio" :rules="rules.required">
        <v-row dense>
          <v-col cols="6">
            <v-radio
                class="pl-5"
                v-for="n in firstRadio"
                :key="n"
                :label="`${n}`"
                :value="n"
            ></v-radio>
          </v-col>
          <v-col cols="6">
            <v-radio
                class="pl-5"
                v-for="n in secondRadio"
                :key="n"
                :label="`${n}`"
                :value="n"
            ></v-radio>
          </v-col>
        </v-row>
      </v-radio-group>
    </div>
    <div v-else-if="inputType === 'additionalQuestion'">
      <v-textarea @keydown.enter.prevent="next" autofocus validate-on-blur :rules="rules.required" placeholder="Na základe čoho ste sa rozhodli pre zvolenú emóciu ?" v-model="object.additionalQuestion"></v-textarea >
    </div>
  </v-form>

</template>

<script>
export default {
  name: 'StepPictures',
  props: {
    pictures: Array,
    picturesWithAdditionalQuestion: Array,
  },
  data() {
    return {
      rules: {
        required: [value => !!value || "Toto pole je povinné."]
      },
      firstRadio: ["Hnev", "Neutrálna tvár", "Opovrhnutie", "Prekvapenie"],
      secondRadio: ["Radosť", "Smútok", "Strach", "Znechutenie"],
      imageSrc: "",
      userResponses: [],
      inputType: "text",
      showAdditionalQuestion: false,
      object: {
        fromTextArea: "",
        fromRadio: "",
        additionalQuestion: "",
      },
    }
  },
  mounted() {
    this.shuffleImages();
    this.getNextPictureUrl();
  },
  methods: {
    shuffleImages() {
      let picturesCopy = [...this.pictures];
      this.sortedPictures = picturesCopy.sort(() => Math.random() - 0.5);
    },
    getNextPictureUrl() {
      if(this.sortedPictures.length) {
        let imageName = this.sortedPictures.pop();
        this.showAdditionalQuestion = this.picturesWithAdditionalQuestion.includes(imageName);
        this.imageSrc = imageName;
      }
    },
    next() {
      if(!this.$refs.form.validate()) {
        return;
      }

      if (this.inputType === "text" && this.object.fromTextArea) {
        this.inputType = "radio";

        // Change button text from Dalej to Odoslat
        if (this.sortedPictures.length === 0 && !this.showAdditionalQuestion) {
          this.$emit("changeNextButtonText");
        }

        return;
      } else if(this.inputType === "radio" && this.object.fromRadio) {
        if(this.showAdditionalQuestion) {
          this.inputType = "additionalQuestion";

          // Change button text from Dalej to Odoslat
          if (this.sortedPictures.length === 0) {
            this.$emit("changeNextButtonText");
          }

          return;
        }
      }

      // Save responses to parent component
      this.inputType = "text";
      let responses = {};
      responses[this.imageSrc] = this.object;
      this.$emit('fromStep', {obj: responses, allow: this.sortedPictures.length === 0});

      if(this.sortedPictures.length) {
        this.$nextTick(() => {
          this.getNextPictureUrl();
          this.inputType = "text";
          this.object = {
            fromTextArea: "",
            fromRadio: "",
            additionalQuestion: "",
          }
        });
      }
    }
  }
}
</script>
<style scoped>

</style>