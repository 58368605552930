<template>
  <v-form ref="form">
    <p style="text-align: justify">
      AKO SA K SEBE OBYČAJNE SPRÁVAM V ŤAŽKÝCH SITUÁCIÁCH
    </p>
    <p style="text-align: justify">
      Pred vyznačením odpovede si, prosím, pozorne prečítajte každú vetu. S použitím nižšie uvedenej
      škály označte príslušné číslo podľa toho, ako často sa tak správate v ťažkých situáciách.
    </p>
    <p><b>1 - takmer nikdy, 2 - zriedka, 3 - niekedy, 4 - často, 5 - takmer vždy</b></p>
    <br>
    <hr>
    <div v-for="question in inputList" :key="question">
      <v-radio-group v-model="radioInputValues[question]" :rules="rules.required">
        <p>{{ question }}</p>
        <v-row dense>
          <v-radio
              class="pl-5"
              v-for="n in 5"
              :key="n"
              :label="`${n}`"
              :value="n"
          />
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon style="position: absolute; right: 10px;" color="blue" v-bind="attrs" v-on="on">info</v-icon>
            </template>
            <span>1 - takmer nikdy, 2 - zriedka, 3 - niekedy, 4 - často, 5 - takmer vždy</span>
          </v-tooltip>
        </v-row>
      </v-radio-group>
      <hr>
    </div>
  </v-form>
</template>

<script>
export default {
  name: "StepScs",
  data: () => ({
    rules: {
      required: [value => !!value || "Toto pole je povinné."]
    },
    inputList: [
      "1. Som odmietavý/á a posudzovačný/á voči svojim vlastným chybám a nedostatkom",
      // "2. Keď som nešťastný/á zvyknem byť posadnutý/á myšlienkami na všetko, čo v mojom živote nie je v poriadku",
      // "3. Keď mi veci nevychádzajú, ťažkosti vnímam ako súčasť života, ktorou prechádza každý človek",
      // "4. Keď rozmýšľam nad svojimi nedostatkami, zvyknem sa cítiť iný/á a odrezaný/á od celého sveta",
      // "5. Keď cítim emocionálnu bolesť, snažím sa byť k sebe láskavý/á",
      // "6. Keď neuspejem v niečom, čo je pre mňa dôležité, som pohltený/á pocitmi vlastnej nedostatočnosti",
      // "7. Keď som psychicky na dne, pripomínam si, že na svete je mnoho ďalších ľudí, ktorí sa cítia rovnako ako ja",
      // "8. V ťažkých časoch mám tendenciu byť sám/sama na seba tvrdý/á",
      // "9. Keď ma niečo rozruší, snažím sa udržať svoje emócie v rovnováhe",
      // "10. Keď sa v niečom cítim nedostatočný/á, pripomínam si, že takého pocity nedostatočnosti má väčšina ľudí",
      // "11. Nemám pochopenie ani trpezlivosť voči aspektom svojej osobnosti, ktoré sa mi nepáčia",
      // "12. Keď mám veľmi ťažké obdobie, zahŕňam sa súcitom a nehou, ktoré potrebujem",
      // "13. Keď som na dne, zvyknem mať pocit, že väčšina ľudí je pravdepodobnejšie šťastnejšia ako ja",
      // "14. Keď sa mi prihodí niečo bolestivé, snažím sa na danú situáciu pozerať vyvážene",
      // "15. Svoje zlyhania sa snažím vnímať ako prejavy ľudskosti",
      // "16. Keď na sebe pozorujem časti seba, ktoré sa mi nepáčia, neustále sa kritizujem",
      // "17. Keď zlyhám v niečom, čo je pre mňa dôležité, snažím sa danú situáciu vnímať s nadhľadom",
      // "18. Keď s niečím naozaj bojujem, zvyčajne cítim, že to ostatným ľuďom musí ísť ľahšie",
      // "19. Keď trpím, som k sebe láskavý/á",
      // "20. Keď ma niečo rozruší, strácam kontrolu nad svojimi pocitmi",
      // "21. Keď sa cítim zle, snažím sa ku svojim pocitom pristupovať so zvedavosťou a otvorenosťou",
      // "22. Som tolerantný/á voči vlastným chybám a nedostatkom",
      // "23. Keď sa mi prihodí niečo bolestivé, zvyknem danú udalosť vnímať horšie než aká v skutočnosti je",
      // "24. Keď zlyhám v niečom, čo je pre mňa dôležité, zvyknem sa v svojom neúspechu cítiť osamelo",
      // "25. Snažím sa byť chápavý/á a trpezlivý/á voči tým častiam svojej osobnosti, ktoré sa mi nepáčia",
    ],
    radioInputValues: {},
  }),
  methods: {
    next() {
      if(!this.$refs.form.validate()) {
        return;
      }
      this.$emit("saveSCS", this.radioInputValues);
    }
  }
}
</script>
