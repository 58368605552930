<template>
<v-card>
  <v-img src="intro_pic.png" ></v-img>
  <v-card-title>
    Dotazník k diplomovej práci
  </v-card-title>
  <v-card-text>
    <h4>Vážený respondent/ respondentka,</h4>
    <p style="text-align: justify">
      sme študentky 5. ročníka sociálnej a pracovnej psychológie a rady by sme Vás poprosili o vyplnenie tohto dotazníka k našej diplomovej práci. Dotazník Vám zaberie približne 20 minút.
      Svojou účasťou prispejte k vedeckým zisteniam v oblasti emócií. Informácie a získané dáta sú prísne dôverné. Dáta budú bezpečne uložené a budú sprístupnené len osobám vykonávajúcim štúdiu.
      Vaša účasť v tomto výskume je dobrovoľná a môžete ju odmietnuť. Ak sa rozhodnete zúčastniť,
      kedykoľvek v priebehu výskumu môžete odstúpiť. Vaša účasť vo výskume nie je spojená so žiadnymi rizikami.
      Potvrdením súhlasu nižšie pokračujete vo výskume.
    </p>
    <p>Za spoluprácu Vám vopred ďakujeme.</p>
    <p>
      V prípade otázok nás kontaktujte na e-mailovej adrese:
      <a href="mailto:lysa7@uniba.sk">lysa7@uniba.sk</a>
    </p>

    <p>
      Bc. Lenka Lysá , Bc. Alexandra Vrábelová, Bc. Katarína Šmehýlová
      <br>
      Mgr. Bronislava Strnádelová, PhD.
      <br>
      Mgr. Martina Baránková, PhD.
    </p>

    <p>
      Ústav aplikovanej psychológie
      <br>
      Fakulta sociálnych a ekonomických vied
      <br>
      Univerzita Komenského v Bratislave
    </p>
    <p></p>
    <br>
    <v-banner color="#d4dde0" elevation="5">
      <v-avatar
          slot="icon"
          color="blue"
          size="30"
      >
        <v-icon
            icon="mdi-lock"
            color="white"
        >
          mdi-pin
        </v-icon>
      </v-avatar>
      Za vyplnenie nášho dotazníka a vyplnenie vašej e-mailovej adresy, máte možnosť vyhrať 50€ poukážku do Alzy. V prípade vyžrebovania Vás budeme kontaktovať ohľadom výhry na Vami uvedený mail.
    </v-banner>

    <v-form ref="form">
      <v-text-field @keydown.enter.prevent="" placeholder="email" v-model="email"></v-text-field>
    </v-form>
  </v-card-text>
</v-card>
</template>

<script>
export default {
  name: "Step1",
  data() {
    return {
      email: "",
    }
  },
  methods: {
    next() {
      this.$emit('saveEmail', this.email);
    }
  }
}
</script>

<style scoped>

</style>