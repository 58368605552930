<template>
  <v-form ref="form">
    <v-card-text>
      <div style="text-align: justify">
        <p>Potvrdzujem, že som bol oboznámený/á a  porozumel/ a som informácii o predkladanej štúdii. Potvrdzujem, že mám viac ako 18 rokov.</p>
        <p>Rozumiem, že moja participácia je dobrovoľná a výskum môžem kedykoľvek opustiť bez udania dôvodu. Ak odstúpim zo štúdie, dáta, ktoré som odoslal/a budú odstránené na základe žiadosti.</p>
        <p>
          Rozumiem, že informácie, ktoré poskytnem zostanú dôverné a budú použité výlučne na účely tejto štúdie.
          <br>
          Prosím, začiarknite políčka nižšie, ak ste sa rozhodli zúčastniť na výskume:
        </p>
      </div>
    </v-card-text>

    <h4>Porozumel/a som a súhlasím s účasťou*</h4>
    <v-radio-group v-model="userData.allowNext" :rules="rules.checkContinue">
      <v-radio
          class="pl-5"
          v-for="n in allowNextRadioOptions"
          :key="n"
          :label="`${n}`"
          :value="n"
      ></v-radio>
    </v-radio-group>

    <div v-if="userData.allowNext === 'áno'">
      <p>Pohlavie</p>
      <v-radio-group :rules="rules.required" v-model="userData.gender">
        <v-radio
            class="pl-5"
            v-for="n in genderOptions"
            :key="n"
            :label="`${n}`"
            :value="n"
        ></v-radio>
      </v-radio-group>

      <v-text-field :rules="rules.age" v-model="userData.age" placeholder="Vek"/>

      <v-select
          :rules="rules.required"
          :items="educationOptions"
          v-model="userData.education"
          label="Vaše najvyššie dosiahnuté vzdelanie"
      ></v-select>

      <v-select
          :rules="rules.required"
          :items="nationalityOptions"
          v-model="userData.nationality"
          label="Národnosť"
      ></v-select>

      <v-select
          :rules="rules.required"
          :items="martialStatusOptions"
          v-model="userData.martialStatus"
          label="Rodinný stav"
      ></v-select>

      <p>Bola vám niekedy určená diagnóza v oblasti mentálneho zdravia ?</p>
      <v-radio-group :rules="rules.required" v-model="userData.mentalDisease">
        <v-radio
            class="pl-5"
            v-for="n in mentalDiseaseOptions"
            :key="n"
            :label="`${n}`"
            :value="n"
        ></v-radio>
      </v-radio-group>
    </div>
  </v-form>
</template>

<script>
export default {
  name: 'Step2',
  data() {
    return {
      rules: {
        checkContinue: [value => value === "áno" || "Musíte súhlasiť ak chcete pokračovať ďalej."],
        required: [value => !!value || "Toto pole je povinné."],
        age: [
            value => !!value || "Toto pole je povinné.",
            // value => Number.isInteger(value) || "Zadaná hodnota nieje číslo",
        ],
      },
      educationOptions: [
        "Základné",
        "Stredoškolské s výučným listom",
        "Stredoškolské s maturitou",
        "Vysokoškolské I. stupňa",
        "Vysokoškolské II. stupňa",
        "Vyššie vzdelanie",
      ],
      nationalityOptions: [
        "Slovenská",
        "Maďarská",
        "Česká",
        "Rómska",
      ],
      martialStatusOptions: [
        "Slobodný/á",
        "Ženatý/Vydatá",
        "Rozvedený/á",
        "Vdovec/Vdova",
        "V partnerskóm vzťahu",
      ],
      mentalDiseaseOptions: ["áno", "nie", "Radšej nechcem uviesť"],
      genderOptions: ["muž", "žena"],
      allowNextRadioOptions: ["áno", "nie"],
      userData: {
        allowNext: null,
        gender: null,
        age: null,
        education: null,
        nationality: null,
        martialStatus: null,
        mentalDisease: null,
      },
    }
  },
  methods: {
    next() {
      if(!this.$refs.form.validate()) {
        return;
      }

      this.$emit('saveUserData', this.userData);
    }
  }
}
</script>